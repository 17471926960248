.homepage {
  position: relative;
  min-height: 100vh;
  overflow-y: auto; /* Allow vertical scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Ensure the video stays in the background and covers the entire screen */
.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire screen */
  z-index: -1; /* Places the video behind other content */
  pointer-events: none; /* Prevents user interactions with the video */
  background-color: black; /* Fallback background color for when video doesn't load */
}

@media (max-width: 800px) {
  .background-video {
    object-fit: cover; /* Reapply to ensure cover behavior on mobile */
    display: block; /* Ensure the video shows */
  }
}

/* Overlay container to hold the "INSERT DISK" button */
.overlay-container {
  position: absolute;
  top: 55%; /* Move the button slightly lower */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure the button is above the video */
}

/* Style for the "INSERT DISK" button */
.insert-disk-btn {
  background-color: transparent;
  color: #aad9cc;
  padding: 5px 10px;
  border: 2px solid #aad9cc; /* Add an outline with the same color as the text */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.insert-disk-btn:hover {
  opacity: 1;
  background-color: #aad9cc; /* Change the background to green on hover */
  color: #fff; /* Change the text color to white on hover */
}

.navbar {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row; /* Align buttons horizontally */
  gap: 30px; /* Increase space between the JOIN and LISTEN buttons */
}

/* Center the buttons for mobile screens */
@media (max-width: 800px) {
  .navbar {
    top: 18px; /* Adjusted top position to align with mute button */
    right: auto; /* Center buttons horizontally */
    left: 50%;
    transform: translateX(-50%); /* Center the navbar */
    flex-direction: row; /* Align buttons horizontally */
    gap: 20px; /* Increase space between the JOIN and LISTEN buttons */
  }

  .mute-btn {
    position: absolute;
    top: 20px; /* Position the button at the top of the screen */
    right: 20px; /* Position the button 20px from the right edge */
    background-color: transparent; /* Transparent background */
    color: #0000ff; /* Button text color */
    border: none; /* Remove the border */
    padding: 5px; /* Smaller padding */
    border-radius: 0; /* Remove the border radius to eliminate the circle */
    cursor: pointer;
    font-size: 24px; /* Icon size */
    transition: color 0.3s ease, background-color 0.3s ease;
    z-index: 1; /* Ensure it is above other content */
  }

  .mute-btn:hover {
    color: #fff; /* Button text color on hover */
    background-color: #0000ff; /* Button background color on hover */
  }
}

/* Add styles for desktop to position the mute button at the bottom right */
@media (min-width: 800px) {
  .mute-btn {
    position: absolute;
    bottom: 50px; /* Position the button at the bottom of the screen */
    right: 20px; /* Position the button 20px from the right edge */
    background-color: transparent; /* Transparent background */
    color: #0000ff; /* Button text color */
    border: none; /* Remove the border */
    padding: 5px; /* Smaller padding */
    border-radius: 0; /* Remove the border radius to eliminate the circle */
    cursor: pointer;
    font-size: 24px; /* Icon size */
    transition: color 0.3s ease, background-color 0.3s ease;
    z-index: 1; /* Ensure it is above other content */
  }

  .mute-btn:hover {
    color: #fff; /* Button text color on hover */
    background-color: #0000ff; /* Button background color on hover */
  }
}

.btn {
  background-color: transparent; /* Make the button background transparent */
  color: #0000ff; /* Button text color */
  border: 2px solid #0000ff; /* Border color */
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.btn:hover {
  color: #fff; /* Button text color on hover */
  background-color: #0000ff; /* Button background color on hover */
}

.presave-btn {
  margin: 10px; /* Add margin for spacing */
  visibility: hidden; /* Initially hide the button */
  opacity: 0; /* Initially make the button invisible */
  transition: opacity 0.5s ease, visibility 0.5s ease;
  position: relative; /* Set position for moving it down */
  top: 240px; /* Move the button a little lower */
  transition: color 0.3s ease, background-color 0.3s ease;
  text-decoration: none;
}

.presave-btn.show {
  visibility: visible; /* Show the button */
  opacity: 1; /* Make the button visible */
}

.social-icons {
  position: absolute;
  bottom: 50px; /* Default bottom margin for larger screens */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
}

/* Move social icons up for mobile screens */
@media (max-width: 800px) {
  .social-icons {
    bottom: 90px; /* Move social icons up for mobile */
    gap: 18px; /* Reduce the gap for mobile */
  }
}

/* Spread out social icons on larger screens */
@media (min-width: 800px) {
  .social-icons {
    gap: 30px; /* Increase the gap for larger screens */
  }
}

@media (max-height: 460px) {
  .social-icons {
    top: 20px;
    left: 20px;
    transform: none; /* Reset the transformation */
    flex-direction: column; /* Stack icons vertically */
    gap: 20px;
  }
}

/* Add styles for screen widths below 1200px to position social icons vertically on the left side */
@media (max-width: 1280px) {
  .social-icons {
    top: 50%; /* Vertically center the icons */
    bottom: auto; /* Reset bottom position */
    left: 20px;
    transform: translateY(-50%); /* Center vertically */
    flex-direction: column; /* Stack icons vertically */
    gap: 20px;
  }
}

.social-icons a {
  color: #0000ff; /* Social icons default color */
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #fff; /* Social icons color on hover */
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.btn-container .btn {
  margin-bottom: 10px; /* Space between the two buttons */
}

.btn-container.show {
  opacity: 1;
  transition: opacity 0.5s;
}

.btn-container .watch-video-btn,
.btn-container .presave-btn {
  width: fit-content; /* Adjust width to fit the button content */
}

